import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { login, checkAuthenticated } from '../actions/auth'

const LoginPage = ({
  login,
  isAuthenticated,
  checkAuthenticated,
  error_message
}) => {
  const [formData, setFormData] = useState({
    password: ''
  })

  const { password } = formData

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const onSubmit = e => {
    e.preventDefault()

    login(password)
  }

  useEffect(() => {
    checkAuthenticated()
  }, [checkAuthenticated])

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />
  }

  return (
    <form
      onSubmit={e => onSubmit(e)}
      className='w-full h-full flex flex-col gap-4 items-center justify-center'
    >
      <h1 className='text-lg font-semibold'>បញ្ចូលពាក្យសម្ងាត់</h1>
      {error_message && (
        <span className='text-sm text-red-400'>{error_message}</span>
      )}
      <div className='flex items-center justify-center'>
        <input
          type='password'
          onChange={e => onChange(e)}
          className='border-1 border-gray-400 outline-none focus:outline-none py-2 px-1'
          name='password'
          value={password}
        />
        <button className='bg-gray-600 h-full px-4' type='submit'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            className='size-6 fill-white'
          >
            <path
              fillRule='evenodd'
              d='M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z'
              clipRule='evenodd'
            />
          </svg>
        </button>
      </div>
    </form>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error_message: state.auth.error_message
})

export default connect(mapStateToProps, { login, checkAuthenticated })(
  LoginPage
)
