import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { MainLayout, DashboardLayout } from './layouts'
import { LoginPage, PosPage, SettingsPage, EditOrderPage } from './pages'
import { Orders, Products, Summary } from './pages/dashboard'

import { AuthGuard } from './guards'

import { Provider } from 'react-redux'
import store from './store'

import './index.css'

const App = () => (
  <Provider store={store}>
    <Router>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route exact path='' element={<MainLayout />}>
            <Route exact path='/dashboard' element={<DashboardLayout />}>
              <Route exact path='orders' element={<Orders />} />
              <Route exact path='products' element={<Products />} />
              <Route exact path='summary' element={<Summary />} />
            </Route>
            <Route exact path='/pos' element={<PosPage />} />
            <Route exact path='/settings' element={<SettingsPage />} />
            <Route exact path='/orders/:id' element={<EditOrderPage />} />
          </Route>
        </Route>
        {/* Public Routes */}
        <Route exact path='/login' element={<LoginPage />} />
      </Routes>
    </Router>
  </Provider>
)

export default App
