import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

import { Navbar } from '../components/dashboard'

const DashboardLayout = () => {
  return (
    <>
      <div className='flex h-full bg-white'>
        <Navbar />

        <main className='px-6 py-4 w-full'>
          <Outlet />
        </main>
      </div>
    </>
  )
}

export default DashboardLayout
