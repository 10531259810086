import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  VERIFY_AUTHENTICATION_SUCCESS,
  VERIFY_AUTHENTICATION_FAIL,
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAIL
} from '../actions/types'

const initialState = {
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  isAuthenticated: null,
  settings: null,
  error_message: ''
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case VERIFY_AUTHENTICATION_SUCCESS:
    case VERIFY_AUTHENTICATION_FAIL:
      return {
        ...state,
        isAuthenticated: payload
      }
    case LOGIN_SUCCESS:
      console.log('LOGIN SUCCESS')
      localStorage.setItem('access', payload.access)
      localStorage.setItem('refresh', payload.refresh)
      return {
        ...state,
        isAuthenticated: true,
        access: payload.access,
        refresh: payload.refresh
      }
    case LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: payload
      }

    case LOGIN_FAIL:
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        settings: null,
        error_message: payload
      }

    case LOGOUT:
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        settings: null
      }
    default:
      return state
  }
}
