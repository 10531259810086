import axios from 'axios'

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  VERIFY_AUTHENTICATION_SUCCESS,
  VERIFY_AUTHENTICATION_FAIL,

  // Settings
  LOAD_SETTINGS_SUCCESS,
  LOAD_SETTINGS_FAIL
} from './types'

export const checkAuthenticated = () => async dispatch => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }

    const body = JSON.stringify({ token: localStorage.getItem('access') })

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/jwt/verify/`,
        body,
        config
      )

      if (res.data.code !== 'token_not_valid') {
        dispatch({
          type: VERIFY_AUTHENTICATION_SUCCESS,
          payload: true
        })
      } else {
        dispatch({
          type: VERIFY_AUTHENTICATION_FAIL,
          payload: false
        })
      }
    } catch (err) {
      dispatch({
        type: VERIFY_AUTHENTICATION_FAIL,
        payload: false
      })

      dispatch({
        type: LOGOUT
      })
    }

    dispatch({
      type: VERIFY_AUTHENTICATION_SUCCESS,
      payload: true
    })
  } else {
    dispatch({
      type: VERIFY_AUTHENTICATION_FAIL,
      payload: false
    })
  }
}

export const login = password => async dispatch => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }

  const body = JSON.stringify({ password })

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login/`,
      body,
      config
    )

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response.data.message
    })
  }
}

export const loadSettings = () => async dispatch => {
  if (localStorage.getItem('access')) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        Accept: 'application/json'
      }
    }

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/settings/`,
        config
      )

      dispatch({
        type: LOAD_SETTINGS_SUCCESS,
        payload: res.data.settings
      })
    } catch (err) {
      dispatch({
        type: LOAD_SETTINGS_FAIL
      })
    }
  } else {
    dispatch({
      type: LOAD_SETTINGS_FAIL
    })
  }
}

export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT
  })
}
