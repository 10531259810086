import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { Outlet, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { checkAuthenticated, loadSettings } from '../actions/auth'
import 'react-toastify/dist/ReactToastify.css'

const MainLayout = ({ isAuthenticated, checkAuthenticated, loadSettings }) => {
  useEffect(() => {
    loadSettings()
  }, [loadSettings])
  return (
    <>
      <Navbar />
      <main className='h-full overflow-hidden'>
        <Outlet />
      </main>
      <ToastContainer className='no-print' />
    </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { checkAuthenticated, loadSettings })(
  MainLayout
)
