import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { EyeIcon } from '../../components/icons/EyeIcon.jsx'
import { EditIcon } from '../../components/icons/EditIcon.jsx'
import { DeleteIcon } from '../../components/icons/DeleteIcon.jsx'
import { Receipt } from '../../components/pos'
import { loadSettings } from '../../actions/auth'
import { connect } from 'react-redux'
import axios from 'axios'
import {
  DateValue,
  now,
  parseAbsoluteToLocal,
  getLocalTimeZone,
  startOfWeek,
  startOfMonth,
  today
} from '@internationalized/date'
import { I18nProvider } from '@react-aria/i18n'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  DatePicker
} from '@nextui-org/react'

const Summary = ({ settings }) => {
  let [date, setDate] = React.useState(
    parseAbsoluteToLocal('2021-04-07T18:45:22Z')
  )

  const [startDate, setStartDate] = useState(
    startOfMonth(today(getLocalTimeZone()), 'km-KH')
  )
  const [endDate, setEndDate] = useState(today(getLocalTimeZone()))
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [filteredOrders, setFilteredOrders] = useState([])
  const [totalFilteredOrders, setTotalFilteredOrders] = useState(0)

  const getOrders = async () => {
    if (localStorage.getItem('access')) {
      setIsLoading(true)
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          Accept: 'application/json'
        }
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/summary/`,
        config
      )

      setOrders(res.data.orders)
      setIsLoading(false)
    }
  }

  const handleFilter = () => {
    console.log('Filter Running')

    if (!startDate || !endDate) {
      setFilteredOrders([])
      return
    }

    // Convert startDate and endDate to Date objects if necessary

    const start = new Date(startDate)
    const end = new Date(endDate)

    const filtered = orders.filter(order => {
      console.log('Order Date:', order.date, 'Start:', start, 'End:', end)

      // Ensure order.date is converted to a valid Date
      const orderDate = new Date(order.date)

      if (isNaN(orderDate)) {
        console.error('Invalid order date:', order.date)
        return false // Skip invalid dates
      }

      // Check if the order date falls within the range
      return orderDate >= start && orderDate <= end
    })

    console.log('FILTERED ORDERS', filteredOrders)

    const total = filtered.reduce(
      (accumulator, current) => accumulator + current.total,
      0
    )

    setFilteredOrders(filtered)
    setTotalFilteredOrders(total)
  }

  useEffect(() => {
    getOrders() // Fetch orders on component load
  }, []) // Run only once when the component mounts

  useEffect(() => {
    handleFilter() // Reapply filtering when orders, startDate, or endDate change
  }, [orders, startDate, endDate]) // Add `orders` as a dependency

  const order_table_columns = [
    {
      key: 'number',
      label: 'ល.រ'
    },
    {
      key: 'date',
      label: 'ថ្ងៃ/ខែ/ឆ្នាំ'
    },
    {
      key: 'time',
      label: 'វេលា'
    },
    {
      key: 'actions',
      label: 'សកម្មភាព'
    },
    {
      key: 'total_usd',
      label: 'តម្លៃសរុប ($)'
    },
    {
      key: 'total_khr',
      label: 'តម្លៃសរុប (៛)'
    }
  ]

  const renderOrderCell = React.useCallback((order, columnKey, orders) => {
    const cellValue = order[columnKey]

    switch (columnKey) {
      case 'number':
        return orders.indexOf(order) + 1

      case 'date':
        const date = new Date(order.created_at)

        const formattedDate = date.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'Asia/Phnom_Penh'
        })

        return formattedDate

      case 'time':
        const datetime = new Date(order.created_at)

        const formattedTime = datetime.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZone: 'Asia/Phnom_Penh'
        })

        return formattedTime
      case 'total_usd':
        return `${(order.total / order.exchange_rate)
          .toFixed(2)
          .toLocaleString()} $`
      case 'total_khr':
        return `${order.total.toLocaleString()} ៛`

      case 'actions':
        return (
          <div className='relative flex justify-center items-center gap-2'>
            <a onClick={() => viewReceipt(order)}>
              <span className='text-lg text-default-400 cursor-pointer active:opacity-50'>
                <EyeIcon />
              </span>
            </a>
            <Link to={`/orders/${order.id}`}>
              <span className='text-lg text-default-400 cursor-pointer active:opacity-50'>
                <EditIcon />
              </span>
            </Link>
            <a onClick={() => openDeleteOrderForm(order)}>
              <span className='text-lg text-danger cursor-pointer active:opacity-50'>
                <DeleteIcon />
              </span>
            </a>
          </div>
        )

      default:
        return cellValue
    }
  }, [])

  const [currentOrder, setCurrentOrder] = useState(null)
  const [size, setSize] = useState('')
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
  const [onViewOrder, setOnViewOrder] = useState(false)
  const [onDeleteOrder, setOnDeleteOrder] = useState(false)

  const viewReceipt = order => {
    setSize('2xl')
    setOnDeleteOrder(false)
    setOnViewOrder(true)
    setCurrentOrder(order)
    onOpen()
  }

  const openDeleteOrderForm = order => {
    setSize('xs')
    setOnViewOrder(false)
    setOnDeleteOrder(true)
    setCurrentOrder(order)
    onOpen()
  }

  const closeModal = () => {
    setOnDeleteOrder(false)
    setOnViewOrder(false)
    setCurrentOrder(null)
    onClose()
  }

  const handleDeleteOrder = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
        Accept: 'application/json'
      }
    }

    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/orders/${currentOrder.id}/`,
      config
    )

    getOrders()
    onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size='2xl'>
        <ModalContent>
          <>
            <ModalHeader className='flex flex-col gap-1'></ModalHeader>
            <ModalBody>
              {onViewOrder && (
                <div className='flex w-full justify-center items-center'>
                  <Receipt order={currentOrder} settings={settings} />
                </div>
              )}

              {onDeleteOrder && (
                <p className='w-full h-auto text-center'>Are you sure?</p>
              )}
            </ModalBody>
            <ModalFooter>
              {onViewOrder && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModal()}
                  >
                    បិទ
                  </button>
                </>
              )}

              {onDeleteOrder && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModal()}
                  >
                    ទេ
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => handleDeleteOrder()}
                  >
                    យល់ព្រម
                  </button>
                </>
              )}
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      <main className='w-full flex flex-col gap-10'>
        <section className='flex flex-row gap-4'>
          <I18nProvider locale='km'>
            <DatePicker
              className='w-full'
              label='ចាប់ផ្ដើម'
              value={startDate}
              onChange={setStartDate}
            />
          </I18nProvider>
          <I18nProvider locale='km'>
            <DatePicker
              className='w-full'
              label='ដល់'
              value={endDate}
              onChange={setEndDate}
              maxValue={today(getLocalTimeZone())}
            />
          </I18nProvider>
        </section>
        <div className='overflow-y-scroll w-full h-[500px]'>
          {settings && (
            <Table
              removeWrapper
              isHeaderSticky
              color='success'
              aria-label='Order Table'
            >
              <TableHeader columns={order_table_columns}>
                {column => (
                  <TableColumn
                    className='bg-gray-400 text-black text-base'
                    align='center'
                    key={column.key}
                  >
                    {column.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody
                items={filteredOrders}
                emptyContent={`${
                  !startDate || !endDate
                    ? 'សូមជ្រើសរើសចន្លោះថ្ងៃ'
                    : 'គ្មានការបញ្ជាទិញនៅចន្លោះថ្ងៃ'
                }`}
                isLoading={isLoading}
                loadingContent={<Spinner label='Loading...' />}
              >
                {item => (
                  <TableRow key={item.key}>
                    {columnKey => (
                      <TableCell className='text-base'>
                        {renderOrderCell(item, columnKey, filteredOrders)}
                      </TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </div>
        <hr className='dash' />
        <section className='flex flex-row justify-between items-center font-semibold text-3xl bg-yellow-400 h-[70px] px-4'>
          <span>តម្លៃសរុប</span>
          <span>{totalFilteredOrders.toLocaleString()} ៛</span>
        </section>
      </main>
    </>
  )
}

const mapStateToProps = state => ({
  settings: state.auth.settings
})

export default connect(mapStateToProps, { loadSettings })(Summary)
