import React from 'react'

const PreviewReceipt = ({ order, settings }) => {
  const date = new Date(order.created_at)

  const formattedTime = date.toLocaleTimeString('kh', {
    hour: '2-digit',
    minute: '2-digit'
  })

  let count = 0

  const getChars = (s, n) => s.substring(s.length - n)

  return (
    <div className='print-only w-[580px] h-auto bg-white flex flex-col p-1'>
      <h1 className='font-bold text-center text-3xl pb-1'>
        {settings.shop_name}
      </h1>
      <p className='text-center text-base pb-1'>
        {`ថ្ងៃទី${date.getDate()} ខែ${
          date.getMonth() + 1
        } ឆ្នាំ${date.getFullYear()}`}
      </p>
      <div className='flex flex-col gap-1 justify-start pb-2 w-full'>
        <section className='flex flex-row justify-between text-base'>
          <div className='text-left pb-1 text-nowrap'>
            <span className='font-bold'>អត្រាប្ដូរប្រាក់:</span>{' '}
            {settings.exchange_rate.toLocaleString()}៛
          </div>
          <div className='text-left pb-1 text-nowrap'>
            <span className='font-bold'>ម៉ោង:</span> {formattedTime.slice(0, 5)}{' '}
            {getChars(formattedTime, 2) == 'AM' ? 'ព្រឹក' : 'ល្ងាច'}
          </div>
        </section>
      </div>
      <hr className='border-dashed border-black mb-2' />
      <div className='font-sans w-full'>
        <table className='w-full h-auto bg-white text-base'>
          <thead className='bg-gray-100'>
            <tr className='bg-black text-white'>
              <th className='py-2 text-center font-semibold'>ល.រ</th>
              <th className='p-2 text-center font-semibold'>ទំនិញ</th>
              <th className='p-2 text-center font-semibold'>ចំនួន</th>
              <th className='p-2 text-center font-semibold'>តម្លៃ</th>
              <th className='p-2 text-center font-semibold'>សរុប</th>
            </tr>
          </thead>

          <tbody>
            {order.order_lines.map((order_line, idx) => {
              count += 1
              return (
                <tr key={order_line.id}>
                  <td className='py-2 text-center'>{count}</td>
                  <td>{order_line.description}</td>
                  <td className='p-1 text-center'>{order_line.qty}</td>
                  <td className='p-1 text-center text-nowrap'>
                    {order_line.unit_price.toLocaleString()} ៛
                  </td>
                  <td className='p-2 text-center text-nowrap'>
                    {order_line.subtotal.toLocaleString()} ៛
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <hr className='border-dashed border-black mt-2' />
      <div className='flex flex-col justify-end items-end pt-2'>
        <p className='font-bold flex flex-row w-full justify-between text-xl text-nowrap'>
          <span>សរុប​ (៛)</span>
          <span className='font-bold'>{order.total.toLocaleString()} ៛</span>
        </p>
      </div>
    </div>
  )
}

export default PreviewReceipt
