import {} from '../actions/types'

const initialState = {
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  isAuthenticated: null,
  settings: null,
  error_message: ''
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    default:
      return state
  }
}
