import React, { useState, useEffect } from 'react'
import { Button, Spinner } from '@nextui-org/react'

import { toast } from 'react-toastify'

import { z, ZodError } from 'zod'

import { loadSettings } from '../actions/auth'

import { connect } from 'react-redux'

import axios from 'axios'

const SettingsPage = ({ settings, loadSettings }) => {
  useEffect(() => {
    loadSettings()
  }, [loadSettings])

  const [edit, setEdit] = useState(false)
  const [formData, setFormData] = useState(null) // Initialize with null explicitly

  useEffect(() => {
    setFormData(settings) // Sync formData with settings when it changes
  }, [settings])

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const editSettings = () => {
    setEdit(true)
  }

  const saveSettings = e => {
    e.preventDefault()

    const { shop_name, tel_1, tel_2, address, exchange_rate } = formData

    const body = JSON.stringify({
      shop_name,
      tel_1,
      tel_2,
      address,
      exchange_rate
    })

    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          Accept: 'application/json'
        }
      }

      const { shop_name, tel_1, tel_2, address, exchange_rate } = formData

      const body = JSON.stringify({
        shop_name,
        tel_1,
        tel_2,
        address,
        exchange_rate
      })

      const res = axios.put(
        `${process.env.REACT_APP_API_URL}/settings/`,
        body,
        config
      )

      
    }

    setEdit(false)
  }

  return (
    <main className='px-10 py-5'>
      {formData ? (
        <form className='w-full h-full flex flex-col' onSubmit={saveSettings}>
          <main className='w-full h-full flex flex-col gap-4 justify-start'>
            <div className='w-full flex flex-row justify-evenly gap-10'>
              <section className='w-full'>
                <h1 className='mb-5 text-lg font-bold'>ព័ត៌មានហាង</h1>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='shop_name'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    ឈ្មោះហាង
                  </label>
                  <input
                    id='shop_name'
                    readOnly={!edit}
                    name='shop_name'
                    value={formData.shop_name}
                    onChange={handleChange}
                    type='text'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='address'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    អាស័យដ្ឋាន
                  </label>
                  <input
                    id='address'
                    readOnly={!edit}
                    name='address'
                    value={formData.address}
                    onChange={handleChange}
                    type='text'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='tel_1'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    លេខទូរស័ព្ទ ១
                  </label>
                  <input
                    id='tel_1'
                    readOnly={!edit}
                    name='tel_1'
                    value={formData.tel_1}
                    onChange={handleChange}
                    type='text'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='tel_2'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    លេខទូរស័ព្ទ ២
                  </label>
                  <input
                    id='tel_2'
                    readOnly={!edit}
                    name='tel_2'
                    value={formData.tel_2}
                    onChange={handleChange}
                    type='text'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
              </section>
              <section className='w-full'>
                <h1 className='mb-5 text-lg font-bold'>ប្រព័ន្ធលក់</h1>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='exchange_rate'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    អត្រាប្ដូរប្រាក់
                  </label>
                  <input
                    id='exchange_rate'
                    readOnly={!edit}
                    name='exchange_rate'
                    value={formData.exchange_rate}
                    onChange={handleChange}
                    type='number'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='opening_time'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    ម៉ោងបើក
                  </label>
                  <input
                    id='opening_time'
                    readOnly={!edit}
                    name='opening_time'
                    value={formData.opening_time}
                    onChange={handleChange}
                    type='text'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
                <div className='group relative w-full mb-3'>
                  <label
                    htmlFor='closing_time'
                    className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                  >
                    ម៉ោងបិទ
                  </label>
                  <input
                    id='closing_time'
                    readOnly={!edit}
                    name='closing_time'
                    value={formData.closing_time}
                    onChange={handleChange}
                    type='text'
                    className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                  />
                </div>
              </section>
            </div>
          </main>
          {edit && (
            <Button
              type='submit'
              color='success'
              size='md'
              className='w-20 text-white font-bold'
            >
              រក្សាទុក
            </Button>
          )}
          {!edit && (
            <Button
              type='button'
              onClick={e => editSettings()}
              size='md'
              className='w-20 text-white font-bold'
            >
              កែសម្រួល
            </Button>
          )}
        </form>
      ) : (
        <div className='w-full h-full flex items-center justify-center'>
          <Spinner />
        </div>
      )}
    </main>
  )
}

const mapStateToProps = state => ({
  settings: state.auth.settings
})

export default connect(mapStateToProps, { loadSettings })(SettingsPage)
