import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Button,
  Input
} from '@nextui-org/react'
import axios from 'axios'
import { SearchIcon } from '../../components/icons/SearchIcon.jsx'
import { EditIcon } from '../../components/icons/EditIcon.jsx'
import { DeleteIcon } from '../../components/icons/DeleteIcon.jsx'
import { z, ZodError } from 'zod'
import { ToastContainer, toast } from 'react-toastify'
import { loadSettings } from '../../actions/auth'

import { connect } from 'react-redux'

const Products = ({ settings, loadSettings }) => {
  useEffect(() => {
    loadSettings()
  }, [loadSettings])
  const table_columns = [
    {
      key: 'sequence',
      label: 'ល.រ'
    },
    {
      key: 'name',
      label: 'ឈ្មោះ'
    },
    {
      key: 'price_usd',
      label: 'តម្លៃ ($)'
    },
    {
      key: 'price',
      label: 'តម្លៃ'
    },
    {
      key: 'actions',
      label: 'សកម្មភាព'
    }
  ]

  const [formData, setFormData] = useState({
    name: '',
    price: 0
  })

  const { name, price } = formData

  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
  const [vendors, setVendors] = useState([])

  const [products, setProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedKeys, setSelectedKeys] = useState(new Set([]))
  const [bulkProducts, setBulkProducts] = useState([])
  const [bulkPrice, setBulkPrice] = useState([])
  const [onAddProduct, setOnAddProduct] = useState(false)
  const [onEditProduct, setOnEditProduct] = useState(false)
  const [onEditSelectedProducts, setOnEditSelectedProducts] = useState(false)
  const [onDeleteSelectedProducts, setOnDeleteSelectedProducts] =
    useState(false)
  const [onDeleteProduct, setOnDeleteProduct] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' })
  const [loading, setLoading] = useState(true)
  const [deleting, setDeleting] = useState(false)

  const fetchProducts = async () => {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
          Accept: 'application/json'
        }
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/products/`,
        config
      )

      setProducts(res.data.products)
      setFilteredProducts(res.data.products)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [setProducts, setFilteredProducts, setLoading])

  // start :: handling search bar
  const handleSearch = e => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(e.target.value.toLowerCase())
    )

    if (e.target.value !== '') {
      setFilteredProducts(filtered)
    } else {
      setFilteredProducts(products)
    }
  }

  // Actions
  const [onImportCsv, setOnImportCsv] = useState(false)
  const [csvFile, setCsvFile] = useState(null)

  const openImportCsvForm = () => {
    setOnAddProduct(false)
    setOnEditProduct(false)
    setOnDeleteProduct(false)
    setOnEditSelectedProducts(false)
    setOnDeleteSelectedProducts(false)
    setOnImportCsv(true)
    setCsvFile(null)
    onOpen()
  }

  const handleUploadFile = e => {
    setCsvFile(e.target.files[0])
  }

  const handleImportProducts = async () => {
    try {
      if (localStorage.getItem('access')) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
            Accept: 'application/json'
          }
        }

        const formData = new FormData()
        formData.append('file', csvFile)

        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/products/import/`,
          formData,
          config
        )

        setCsvFile(null)

        if (res.data.success) {
          toast.success('បញ្ចូលទំនិញបានសម្រេច')
          fetchProducts()
        }
      }
    } catch (error) {
      console.error(error)
      toast.error('បញ្ចូលទំនិញមិនបានសម្រេច')
    } finally {
      closeModalForm()
    }
  }

  const openAddProductForm = () => {
    setOnAddProduct(true)
    setOnEditProduct(false)
    setOnDeleteProduct(false)
    setOnEditSelectedProducts(false)
    setOnEditSelectedProducts(false)
    setOnImportCsv(false)
    setFormData({
      name: '',
      price: 0
    })
    onOpen()
  }

  const closeModalForm = () => {
    setOnAddProduct(false)
    setOnEditProduct(false)
    setOnDeleteProduct(false)
    setOnEditSelectedProducts(false)
    setOnEditSelectedProducts(false)
    setOnImportCsv(false)
    setFormData({
      name: '',
      price: 0
    })
    setBulkPrice(0)
    setCsvFile(null)
    onClose()
  }

  const handleAddProduct = async e => {
    try {
      if (localStorage.getItem('access')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access')}`,
            Accept: 'application/json'
          }
        }

        const body = JSON.stringify({ name, price })

        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/products/`,
          body,
          config
        )

        setFormData({
          name: '',
          price: 0
        })
      }

      toast.success(`បញ្ចូលទំនិញ​​ ${formData.name}`)

      fetchProducts()
    } catch (error) {
      toast.error('បញ្ចូលទំនិញមិនបានសម្រេច')
    } finally {
      closeModalForm()
    }
  }

  const openEditProductForm = product => {
    setOnAddProduct(false)
    setOnDeleteProduct(false)
    setOnEditProduct(true)
    setOnEditSelectedProducts(false)
    setOnEditSelectedProducts(false)
    setOnImportCsv(false)
    setFormData(product)
    onOpen()
  }

  const handleChange = e => {
    const { name, value } = e.target
    let parsedValue

    // Convert to float for wholesale_price and retail_price
    if (name === 'price' && value !== '') {
      parsedValue = parseFloat(value)
    } else {
      parsedValue = value
    }

    setFormData({ ...formData, [name]: parsedValue })
  }

  const handleEditProduct = async () => {
    try {
      if (localStorage.getItem('access')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access')}`,
            Accept: 'application/json'
          }
        }

        const body = JSON.stringify({ name, price })

        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/products/${formData.id}/`,
          body,
          config
        )

        toast.success('Product has been updated.')
      }

      fetchProducts()
      closeModalForm()
    } catch (error) {
      toast.error('Failed to update product.')
    }
  }

  const openDeleteProductForm = product => {
    setOnAddProduct(false)
    setOnDeleteProduct(true)
    setOnEditProduct(false)
    setOnEditSelectedProducts(false)
    setOnEditSelectedProducts(false)
    setOnImportCsv(false)
    setFormData(product)
    onOpen()
  }

  const handleDeleteProduct = async () => {
    try {
      if (localStorage.getItem('access')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access')}`,
            Accept: 'application/json'
          }
        }

        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/products/${formData.id}/`,
          config
        )

        toast.success(`ទំនិញ ${formData.name} បានដកចេញ`)
      }
      fetchProducts()
      setDeleting(false)
      closeModalForm()
    } catch (error) {
      toast.error('Failed to delete product.')
      setDeleting(false)
    }
  }

  useEffect(() => {
    if (selectedKeys === 'all') {
      setBulkProducts(filteredProducts.map(product => product.id))
    } else {
      setBulkProducts([...selectedKeys])
    }
    console.log('BULKPRODUCTS', bulkProducts)
  }, [selectedKeys, setBulkProducts])

  const onOpenEditSelectedProductsForm = () => {
    setOnAddProduct(false)
    setOnEditProduct(false)
    setOnDeleteProduct(false)
    setOnEditSelectedProducts(true)
    setOnImportCsv(false)
    setBulkPrice(0)
    onOpen()
  }

  const handleEditSelectedProducts = async () => {
    try {
      if (localStorage.getItem('access')) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access')}`,
            Accept: 'application/json'
          }
        }

        const body = JSON.stringify({
          products: bulkProducts,
          price: bulkPrice
        })

        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/products/`,
          body,
          config
        )

        toast.success(`តម្លៃទំនិញបានកែ`)
        setBulkProducts([])
        setSelectedKeys(new Set([]))
      }
      fetchProducts()
      setDeleting(false)
      closeModalForm()
    } catch (error) {
      toast.error('Failed to delete product.')
      setDeleting(false)
    }
  }

  const onOpenDeleteSelectedProductsForm = () => {
    setOnAddProduct(false)
    setOnEditProduct(false)
    setOnDeleteProduct(false)
    setOnEditSelectedProducts(false)
    setOnDeleteSelectedProducts(true)
    setOnImportCsv(false)
    setBulkPrice(0)
    onOpen()
  }

  const deleteSelectedProducts = async () => {
    try {
      if (localStorage.getItem('access')) {
        const body = JSON.stringify({
          products: bulkProducts
        })

        console.log('BODY', body)

        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/products/`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('access')}`,
              Accept: 'application/json'
            },
            data: body
          }
        )

        toast.success(`ទំនិញបានដកចេញ`)
        setBulkProducts([])
        setSelectedKeys(new Set([]))
      }
      fetchProducts()
      setDeleting(false)
      closeModalForm()
    } catch (error) {
      toast.error('Failed to delete product.')
      setDeleting(false)
    }
  }

  const renderCell = React.useCallback(
    (product, columnKey, settings, filteredItems) => {
      const cellValue = product[columnKey]

      switch (columnKey) {
        case 'sequence':
          return filteredItems.indexOf(product) + 1

        case 'price_usd':
          return `${(product.price / settings.exchange_rate)
            .toFixed(2)
            .toLocaleString()} $`
        case 'price':
          return `${cellValue.toLocaleString()} ៛`
        case 'actions':
          return (
            <div className='relative flex items-center gap-2'>
              <a onClick={() => openEditProductForm(product)}>
                <span className='text-lg text-default-400 cursor-pointer active:opacity-50'>
                  <EditIcon />
                </span>
              </a>
              <a onClick={() => openDeleteProductForm(product)} color='danger'>
                <span className='text-lg text-danger cursor-pointer active:opacity-50'>
                  <DeleteIcon />
                </span>
              </a>
            </div>
          )
        default:
          return cellValue
      }
    },
    []
  )

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          <>
            <ModalHeader className='flex flex-col gap-1'>
              {onAddProduct && 'បញ្ចូលទំនិញថ្មី'} {onEditProduct && 'កែទំនិញ'}
            </ModalHeader>
            <ModalBody>
              {(onAddProduct || onEditProduct) && (
                <form className='w-30 h-full'>
                  <div className='group relative w-full mb-3'>
                    <label
                      htmlFor='name'
                      className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                    >
                      ឈ្មោះ
                    </label>
                    <input
                      name='name'
                      type='text'
                      className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                      value={formData.name}
                      onChange={e => handleChange(e)}
                    />
                  </div>

                  <div className='group relative w-full mb-3'>
                    <label
                      htmlFor='price'
                      className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                    >
                      តម្លៃ
                    </label>
                    <input
                      name='price'
                      type='number'
                      className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                      value={formData.price}
                      onChange={e => handleChange(e)}
                    />
                  </div>
                </form>
              )}
              {onEditSelectedProducts && (
                <form className='w-30 h-full'>
                  <div className='group relative w-full mb-3'>
                    <label
                      htmlFor='price'
                      className='block w-full pb-1 text-sm font-medium text-gray-500 transition-all duration-200 ease-in-out group-focus-within:text-green-400'
                    >
                      តម្លៃ
                    </label>
                    <input
                      name='price'
                      type='number'
                      className='peer h-10 w-full rounded-md bg-gray-50 border border-gray-400 px-4 font-thin outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:ring-2 focus:ring-green-400'
                      value={bulkPrice}
                      onChange={e => setBulkPrice(e.target.value)}
                    />
                  </div>
                </form>
              )}

              {onDeleteProduct && (
                <p className='w-full h-auto text-center'>
                  តើ​អ្នក​​ចង់លុប​ទំនិញឈ្មោះ{' '}
                  <span className='font-bold'>
                    <q>{formData.name}</q>
                  </span>
                  ?
                </p>
              )}

              {onDeleteSelectedProducts && (
                <p className='w-full h-auto text-center'>
                  តើ​អ្នក​​ចង់លុប​ទំនិញទាំងនេះ{' '}
                  <span className='font-bold'></span>?
                </p>
              )}

              {onImportCsv && (
                <form>
                  <input
                    type={'file'}
                    accept={'.xlsx'}
                    onChange={e => {
                      handleUploadFile(e)
                    }}
                  />
                </form>
              )}
            </ModalBody>
            <ModalFooter>
              {onAddProduct && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModalForm()}
                  >
                    បោះបង់
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => handleAddProduct()}
                  >
                    បញ្ចូល
                  </button>
                </>
              )}

              {onEditProduct && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModalForm()}
                  >
                    បោះបង់
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => handleEditProduct()}
                  >
                    រក្សាទុក
                  </button>
                </>
              )}

              {onEditSelectedProducts && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModalForm()}
                  >
                    បោះបង់
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => handleEditSelectedProducts()}
                  >
                    រក្សាទុក
                  </button>
                </>
              )}

              {onDeleteProduct && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModalForm()}
                  >
                    ទេ
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => handleDeleteProduct()}
                  >
                    យល់ព្រម
                  </button>
                </>
              )}

              {onDeleteSelectedProducts && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModalForm()}
                  >
                    ទេ
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => deleteSelectedProducts()}
                  >
                    យល់ព្រម
                  </button>
                </>
              )}

              {onImportCsv && (
                <>
                  <button
                    className='text-white bg-red-500 hover:bg-red-700 rounded-md px-4 py-2'
                    onClick={() => closeModalForm()}
                  >
                    ទេ
                  </button>
                  <button
                    className='text-white bg-green-500 hover:bg-green-700 px-4 py-2 rounded-md'
                    onClick={() => handleImportProducts()}
                  >
                    បញ្ចូលទំនិញ
                  </button>
                </>
              )}
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      <section className='w-full h-auto py-[15px] flex flex-row justify-between items-center flex-nowrap gap-10'>
        <Input
          classNames={{
            base: 'w-full h-10',
            mainWrapper: 'h-full',
            input: 'text-small',
            inputWrapper:
              'h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20'
          }}
          placeholder='ស្វែងរក...'
          size='md'
          startContent={<SearchIcon size={18} />}
          type='search'
          onChange={handleSearch}
        />
        <div className='flex flex-row gap-2'>
          <Button
            size='md'
            onClick={() => openAddProductForm()}
            color='success'
            className='text-white'
          >
            បញ្ចូលទំនិញ
          </Button>
          <Button
            isDisabled={selectedKeys.size === 0}
            onClick={() => onOpenEditSelectedProductsForm()}
            color='secondary'
          >
            កែតម្លៃ
            {bulkProducts.length === 0 ? '' : ` (${bulkProducts.length})`}
          </Button>
          <Button
            isDisabled={selectedKeys.size === 0}
            onClick={() => onOpenDeleteSelectedProductsForm()}
            color='danger'
          >
            <DeleteIcon />
            {bulkProducts.length === 0 ? '' : ` (${bulkProducts.length})`}
          </Button>
          <Button
            size='md'
            onClick={() => openImportCsvForm()}
            className='text-white'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='size-6 text-black'
            >
              <path d='M11.47 1.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1-1.06 1.06l-1.72-1.72V7.5h-1.5V4.06L9.53 5.78a.75.75 0 0 1-1.06-1.06l3-3ZM11.25 7.5V15a.75.75 0 0 0 1.5 0V7.5h3.75a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h3.75Z' />
            </svg>
          </Button>
        </div>
      </section>

      {settings && (
        <div className='overflow-y-scroll h-[650px]'>
          <Table
            selectedKeys={selectedKeys}
            selectionMode='multiple'
            onSelectionChange={setSelectedKeys}
            aria-label='Product List'
          >
            <TableHeader columns={table_columns}>
              {column => (
                <TableColumn key={column.key}>{column.label}</TableColumn>
              )}
            </TableHeader>
            <TableBody
              items={filteredProducts}
              emptyContent='គ្មានទំនិញសម្រាប់បង្ហាញ'
            >
              {item => (
                <TableRow key={item.key}>
                  {columnKey => (
                    <TableCell>
                      {renderCell(item, columnKey, settings, filteredProducts)}
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  settings: state.auth.settings
})

export default connect(mapStateToProps, { loadSettings })(Products)
